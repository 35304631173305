<template>
  <li class="nav-item" v-if="$store.state.active == 0 && $store.state.phone_verify == 0">
    <router-link to="/phone-verify" class="nav-link">
      <i class="fa fa-phone text-danger"> </i><span class="text-danger"> Phone verify</span>
    </router-link>
  </li>
  <li class="nav-item dropdown" v-if="$store.state.active == 1">
    <a
      class="nav-link dropdown-toggle"
      href="#"
      id="navbarNotification"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="badge rounded-pill bg-danger" v-if="$store.state.totalNotifications > 0">{{
        $store.state.totalNotifications
      }}</span>
      <i class="fa fa-bell"> </i>
      <span class="d-lg-none d-inline">Notification</span>
    </a>
    <ul
      class="dropdown-menu dropdown-menu-end"
      aria-labelledby="navbarNotification"
      id="notificationDropdown"
    >
      <!--            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer interdum neque ipsum, ut feugiat risus interdum in. Quisque vehicula malesuada varius. Mauris eget tellus ex. Duis egestas sollicitudin purus sit amet tempor. Vestibulum dui quam, aliquam vel dolor ut, pharetra mattis enim. Nam sed urna ut risus auctor ull</li>-->
      <!--            <li><a class="dropdown-item" href="#">Another action</a></li>-->
      <li v-for="(item, index) in notifications" :key="index">
        <small class="badge bg-danger">{{ item.notification_subject }}</small>
        {{ item.notification_message }}
      </li>
      <li v-if="$store.state.totalNotifications > 0">
        <a class="dropdown-item text-center" href="#">View All</a>
      </li>
      <li v-else><a class="dropdown-item text-center" href="#">There's no notification</a></li>
    </ul>
  </li>
  <li class="nav-item dropdown" v-if="$store.state.role != 'partner'">
    <a
      class="nav-link dropdown-toggle"
      href="#"
      id="navbaUser"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fa fa-user"> </i>
      <span :title="$store.state.name">Hi, {{ $store.state.name }} </span>
    </a>
    <ul
      class="dropdown-menu dropdown-menu-end 123"
      aria-labelledby="navbaUser"
      :id="userDropdownId"
    >
      <li class="user-summary d-flex" v-if="$store.state.active == 1">
        <div class="p-1">
          <div class="c100 p90 orange center">
            <span>90%</span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1 p-1">
          <div class="lead text-primary">Profile Incomplete</div>
          <p>Complete missing info to increase trust scale.</p>
          <a href="#" class="complete-profile"
            >COMPLETE PROFILE<i class="fa fa-long-arrow-right"></i
          ></a>
        </div>
      </li>
      <li v-if="$store.state.active == 1">
        <router-link :to="'/' + $store.state.currentRole + '/dashboard'" class="dropdown-item">
          <span class="fa fa-th-large"></span>&nbsp;Dashboard
        </router-link>
      </li>
      <li>
        <router-link :to="'/profile/' + $store.state.unique_id" class="dropdown-item">
          <i class="fa fa-user-cog"> </i> My Profile
        </router-link>
      </li>
      <li>
        <router-link :to="'/user/my-address'" class="dropdown-item">
          <i class="fa fa-address-card"> </i> My Address
        </router-link>
      </li>
      <li>
        <router-link :to="'/user/trust-verification'" class="dropdown-item">
          <i class="fa fa-check-circle"> </i> Trust & Verification
        </router-link>
      </li>
      <li>
        <router-link :to="'/account-setting/notification'" class="dropdown-item">
          <i class="fa fa-cog"> </i> Account Setting
        </router-link>
      </li>
      <li class="top-user-menu-li" @click="logoutClick">
        <a href="#" class="dropdown-item">
          <span class="fas fa fa-sign-out-alt text-primary"></span>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </li>
  <li class="nav-item dropdown" v-if="$store.state.role == 'partner'">
    <a
      class="nav-link dropdown-toggle"
      href="#"
      id="navbarPartner"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fa fa-user"> </i>
      <span :title="$store.state.name">Hi, {{ $store.state.name }} </span>
    </a>
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarPartner">
      <li>
        <router-link :to="'/partner/detail'" class="dropdown-item"> Profile </router-link>
      </li>
      <li>
        <router-link :to="'/partner/passenger/add'" class="dropdown-item">
          Add passenger info
        </router-link>
      </li>
      <li>
        <router-link :to="'/partner/passenger/list'" class="dropdown-item">
          View passenger list
        </router-link>
      </li>
      <li class="top-user-menu-li" @click="logoutPartnerClick">
        <a href="#" class="dropdown-item">
          <span class="fas fa fa-sign-out-alt text-primary"></span>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
// import { mapGetters } from 'vuex'
require('bootstrap');
export default {
  name: 'LiLoggedin',
  // props: {
  //     notHomepage: {
  //         type: Number,
  //         default: true
  //     },
  // },
  components: {},
  methods: {
    logoutClick: function () {
      this.$store.commit('logout');
      this.$router.push('/');
    },
    logoutPartnerClick: function () {
      this.$store.commit('logout');
      this.$router.push('/');
    },
  },
  computed: {
    // ...mapGetters({
    //     state: 'getState'
    // }),
    userDropdownId: function () {
      return this.$store.state.active == 1 ? 'userDropdown' : 'userDropdown1';
    },
    notifications: function () {
      if (this.$store.state.notifications == null) return [];
      let tmp = JSON.parse(this.$store.state.notifications);
      for (let i = 0; i < tmp.length; i++) {
        tmp[i]['notification_message'] =
          tmp[i]['notification_message'].length > 120
            ? tmp[i]['notification_message'].substring(0, 120 - 3) + '...'
            : tmp[i]['notification_message'];
      }
      return tmp;
    },
  },
  created() {
    // console.log("notifications")
    // console.log(notifications)
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/app_home.scss';
</style>
